import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Work = props => {
  const title = "Contact"

  return (
    <Layout>
      <Seo title={title} />
      <div className="flex">
        <div className="w-2/3" style={{ minHeight: "400px" }}>
          <p>
            (415) 706-1724
            <br />
            <a href="mailto:cindyepps@comcast.net">cindyepps@comcast.net</a>
          </p>
        </div>
        <div className="w-1/3 px-8"></div>
      </div>
    </Layout>
  )
}

export default Work
